import { Card, CardContent } from "@/components/ui/card";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table";
import { DateRangePicker, DateRangePickerItem, TextInput } from "@tremor/react";
import { el } from "date-fns/locale";
import { ChevronDownIcon, ChevronUpIcon, SearchIcon } from "lucide-react";
import type React from "react";
import { useMemo, useState } from "react";

interface Column {
	key: string;
	header: string;
	render?: (value: any, row: any) => React.ReactNode;
	sortable?: boolean;
	searchFunction?: (value: any, searchTerm: string) => boolean;
}

interface ReusableTableProps {
	data: any[];
	columns: Column[];
	onRowClick?: (row: any) => void;
	searchableFields?: string[];
	onDateRangeChange?: (range: { from?: Date; to?: Date }) => void;
	period?: string;
}

const ReusableTable: React.FC<ReusableTableProps> = ({
	data,
	columns,
	onRowClick,
	searchableFields = [],
	onDateRangeChange,
	period,
}) => {
	const [sortConfig, setSortConfig] = useState<{
		key: string;
		direction: "asc" | "desc";
	} | null>(null);
	const [searchTerm, setSearchTerm] = useState("");

	const requestSort = (key: string) => {
		let direction: "asc" | "desc" = "asc";
		if (
			sortConfig &&
			sortConfig.key === key &&
			sortConfig.direction === "asc"
		) {
			direction = "desc";
		}
		setSortConfig({ key, direction });
	};

	const sortedData = useMemo(() => {
		if (!Array.isArray(data)) return [];

		const sortableItems = [...data];
		if (sortConfig !== null) {
			sortableItems.sort((a, b) => {
				const aValue = a[sortConfig.key];
				const bValue = b[sortConfig.key];

				// Handle null/undefined values
				if (aValue == null) return sortConfig.direction === "asc" ? -1 : 1;
				if (bValue == null) return sortConfig.direction === "asc" ? 1 : -1;

				// Handle dates
				if (aValue instanceof Date && bValue instanceof Date) {
					return sortConfig.direction === "asc"
						? aValue.getTime() - bValue.getTime()
						: bValue.getTime() - aValue.getTime();
				}

				// Handle regular values
				if (aValue < bValue) {
					return sortConfig.direction === "asc" ? -1 : 1;
				}
				if (aValue > bValue) {
					return sortConfig.direction === "asc" ? 1 : -1;
				}
				return 0;
			});
		}
		return sortableItems;
	}, [data, sortConfig]);

	const filteredData = useMemo(() => {
		if (searchTerm === "") return sortedData;
		return sortedData.filter((row) => {
			const fieldsToSearch =
				searchableFields.length > 0 ? searchableFields : Object.keys(row);
			return fieldsToSearch.some((field) => {
				const column = columns.find((col) => col.key === field);
				if (column?.searchFunction) {
					return column.searchFunction(row[field], searchTerm);
				}
				return String(row[field])
					.toLowerCase()
					?.includes(searchTerm.toLowerCase());
			});
		});
	}, [sortedData, searchTerm, searchableFields, columns]);

	const handleDateRangeChange = (range: { from?: Date; to?: Date }) => {
		const { from, to } = range;

		const fromDate = from ? new Date(from) : undefined;
		const toDate = to ? new Date(to) : undefined;

		if (fromDate) {
			fromDate.setHours(0, 0, 0, 0);
		}
		if (toDate) {
			toDate.setHours(23, 59, 59, 999);
		}

		onDateRangeChange?.({ from: fromDate, to: toDate });
	};

	return (
		<Card className="col-span-4">
			<CardContent className="pt-2 pl-2">
				<div className="flex flex-row items-center justify-between w-full mb-5 mt-5 flex-wrap">
					<div className="flex items-center">
						<TextInput
							placeholder="Αναζήτηση..."
							value={searchTerm}
							onValueChange={setSearchTerm}
							className="max-w-sm"
							icon={SearchIcon}
						/>
					</div>
					<div className="pr-10 min-w-1">
						<DateRangePicker
							placeholder="Επιλέξτε περίοδο"
							selectPlaceholder="Προεπιλεγμένα Διαστήματα"
							locale={el}
							onValueChange={handleDateRangeChange}
							defaultValue={{ selectValue: period }}
						>
							<DateRangePickerItem
								key="today"
								value="Σήμερα"
								to={new Date()}
								from={new Date()}
							/>
							<DateRangePickerItem
								key="7days"
								value="Τελευταίες 7 ημέρες"
								to={new Date()}
								from={new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)}
							/>
							<DateRangePickerItem
								key="30days"
								value="Τελευταίες 30 ημέρες"
								to={new Date()}
								from={new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)}
							/>
							<DateRangePickerItem
								key="monthToDate"
								value="Από αρχή του μήνα έως σήμερα"
								to={new Date()}
								from={
									new Date(new Date().getFullYear(), new Date().getMonth(), 1)
								}
							/>
							<DateRangePickerItem
								key="yearToDate"
								value="Από αρχή του χρόνου έως σήμερα"
								to={new Date()}
								from={new Date(new Date().getFullYear(), 0, 1)}
							/>
						</DateRangePicker>
					</div>
				</div>

				<Table>
					<TableHeader>
						<TableRow>
							{columns?.map((column, index) => (
								<TableHead
									key={`header-${index}`}
									className="max-w-[250px] cursor-pointer"
									onClick={() =>
										column.sortable !== false && requestSort(column.key)
									}
								>
									<div className="flex items-center">
										{column.header}
										{column.sortable !== false && (
											<span className="ml-2">
												{sortConfig?.key === column.key ? (
													sortConfig.direction === "asc" ? (
														<ChevronUpIcon className="h-4 w-4" />
													) : (
														<ChevronDownIcon className="h-4 w-4" />
													)
												) : (
													<ChevronUpIcon className="h-4 w-4 opacity-0" />
												)}
											</span>
										)}
									</div>
								</TableHead>
							))}
						</TableRow>
					</TableHeader>
					<TableBody>
						{filteredData.map((row, rowIndex) => (
							<TableRow
								key={`row-${rowIndex}`}
								className="cursor-pointer"
								onClick={() => onRowClick?.(row)}
							>
								{columns.map((column, cellIndex) => (
									<TableCell key={`cell-${rowIndex}-${cellIndex}`}>
										{column.render
											? column.render(row[column.key], row)
											: row[column.key]}
									</TableCell>
								))}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
};

export default ReusableTable;
