import useAxiosPrivate from "@/hooks/useAxiosPrivate";
import { formatDate } from "@/utils";
import { Icon } from "@tremor/react";
import { CoinsIcon } from "lucide-react";
import { useEffect, useState } from "react";
import ReusableTable from "../components/ReusableTable";

interface LogData {
	data: {
		email: string;
		action: string;
		kind: string;
		is_manual: boolean;
		subscription_id: string;
		created_at: string;
	}[];
}

export default function OrganizationLogs({ org_id }) {
	const [data, setData] = useState<LogData | null>(null);
	const axiosPrivate = useAxiosPrivate();
	const [dateRange, setDateRange] = useState<{ from?: string; to?: string }>({
		from: new Date(new Date(Date.now()).setHours(0, 0, 0, 0)).toISOString(),
		to: new Date(new Date(Date.now()).setHours(23, 59, 59, 999)).toISOString(),
	});
	const addParams = ["?"];
	if (org_id) {
		addParams.push(`org_id=${org_id}`);
	}
	if (dateRange.from) {
		addParams.push(`from_date=${new Date(dateRange.from).toISOString()}`);
	}
	if (dateRange.to) {
		addParams.push(`to_date=${new Date(dateRange.to).toISOString()}`);
	}

	useEffect(() => {
		const getUserLogs = async () => {
			const response = await axiosPrivate.get(
				`/auth/users/logs${addParams.join("&")}`,
			);
			setData(await response.data);
		};
		getUserLogs();
	}, [dateRange]); // Add dateRange as dependency

	const actionSearchFunction = (value: string, searchTerm: string) => {
		const lowerSearchTerm = searchTerm.toLowerCase();
		if (value === "add") {
			return "καταναλώθηκε".includes(lowerSearchTerm);
		}
		if (value === "refund") {
			return "αποζημιώθηκε".includes(lowerSearchTerm);
		}
		return value.toLowerCase().includes(lowerSearchTerm);
	};

	const columns = [
		{
			key: "email",
			header: "Email",
			render: (value: string) => <div>{value}</div>,
		},
		{
			key: "action",
			header: "Credit",
			render: (value: string) => (
				<div className="flex flex-row items-center">
					{value.includes("add") ? "Καταναλώθηκε" : "Αποζημιώθηκε"}{" "}
					{value?.includes("Refund") && (
						<Icon icon={CoinsIcon} tooltip="Έγινε Refund" />
					)}
				</div>
			),
			searchFunction: actionSearchFunction,
		},
		{
			key: "kind",
			header: "Ενέργεια",
			render: (value: string) => (
				<div>{value.includes("check") ? "Επανέλεγχος" : "Εισαγωγή"}</div>
			),
		},
		{
			key: "kind",
			header: "Τύπος",
			render: (value: string) => (
				<div>{value.includes("afm") ? "ΑΦΜ" : "KAEK"}</div>
			),
		},
		{
			key: "is_manual",
			header: "Χειροκίνητα",
			render: (value: boolean) => <div>{value ? "Ναι" : "Οχι"}</div>,
		},
		{
			key: "subscription_id",
			header: "id Συνδρομής",
			render: (value: boolean) => <div>{value}</div>,
		},
		{
			key: "created_at",
			header: "Ημ/νία Εγγραφής",
			render: (value: string) =>
				formatDate(value, { simple: true, withTime: true }),
		},
	];

	return (
		<div className="flex-1 space-y-4 p-8 pt-6">
			<div className="flex items-center justify-start space-y-2">
				<h2 className="text-2xl font-bold tracking-tight">
					Καταγραφή ενεργειών οργανισμού
				</h2>
			</div>
			<div className="flex flex-col space-y-4">
				<ReusableTable
					data={data?.data || []}
					columns={columns}
					searchableFields={["email", "action"]}
					onDateRangeChange={(range) => {
						setDateRange({
							from: range.from?.toISOString(),
							to: range.to?.toISOString(),
						});
					}}
					period="Σήμερα"
				/>
			</div>
		</div>
	);
}
